<template>
  <div>测试123</div>
</template>

<script>
import PointConvert from "@/utils/PointConvert.js";
export default {
  created() {
    console.log(123);
  },
  mounted() {
    console.log(122);
    this.transformPos();
  },
  methods: {
    transformPos() {
      let arr1 = [
        [31.784163, 113.274235],
        [31.729, 113.258],
        [31.903892, 113.269402],
        [31.756388, 113.476111],
        [31.597232, 113.804174],
        [31.609118, 113.341631],
        [31.730912, 113.394222],
        [32.209636, 113.365847],
        [31.606476, 113.816998],
        [31.676089, 113.367883],
        [31.800406, 113.082803],
        [31.66482, 112.920245],
        [31.597556, 114.012455],
        [31.506053, 113.773978],
        [32.071128, 113.499122],
        [31.712221, 113.340344],
        [31.97359, 113.132522],
        [31.694009, 113.411483],
        [31.797467, 113.739249],
        [31.73264, 113.383152],
        [31.680372, 113.489251],
        [31.867494, 113.305214],
        [31.630479, 113.848783],
        [31.75171, 113.205551],
        [31.536281, 113.223178],
        [31.511531, 113.989448],
        [31.800275, 113.344372],
        [31.614274, 113.892021],
        [31.705666, 113.397325],
        [32.198942, 113.070485],
        [31.604546, 113.552446],
        [32.07217, 113.49782],
        [32.308589, 113.733655],
        [31.764444, 113.6202],
        [31.67282, 113.443823],
        [31.502726, 114.018619],
        [31.715333, 113.41754],
        [32.179504, 113.257505],
        [31.721012, 113.362821],
        [31.511907, 113.036864],
        [31.963289, 113.443442],
        [31.757845, 113.349371],
        [31.897, 113.315],
        [31.792674, 113.44672],
      ];
      let arr2 = [
        [32.003611, 113.568],
        [32.23, 113.3475],
        [31.93, 113.6088],
        [31.7511, 113.5075],
        [31.68111, 113.537224],
        [31.7008, 113.8802],
        [31.798889, 113.896944],
        [31.4636, 113.6663],
        [31.611962, 112.774981],
        [31.69, 114.1],
        [31.8904, 113.7915],
        [32.110933, 113.668479],
        [31.7755, 113.7102],
        [31.493, 113.864],
        [32.179, 113.374],
        [31.850704, 113.48245],
        [31.7455, 113.9714],
        [31.6663, 113.9872],
        [31.93, 113.7],
        [32.208433, 113.373255],
        [31.7722, 113.5161],
        [31.552044, 114.04748],
        [32.112939, 113.477818],
        [32.0572, 113.2577],
        [32.306, 113.108],
        [32.1805, 113.535],
        [31.461389, 113.8333],
        [31.807495, 113.429666],
        [31.559868, 113.45725],
      ];
      let newArr1 = [];
      let newArr2 = [];
      arr1.forEach((item) => {
        // console.log(item);
        let pos = PointConvert.wgs84togcj02(item[1], item[0]);
        // console.log(pos);
        newArr1.push(pos);
      });
      arr2.forEach((item) => {
        let pos = PointConvert.wgs84togcj02(item[1], item[0]);
        // console.log(pos);
        newArr2.push(pos);
      });
      //   let pos = PointConvert.wgs84togcj02(113.44672, 31.792674);
      //   console.log(pos);
      console.log(JSON.stringify(newArr1));
      console.log(JSON.stringify(newArr2));
    },
  },
};
</script>

<style>
</style>